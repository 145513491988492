/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 React.glb
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function ReactModel() {
  const { nodes, materials } = useGLTF('../models/React.glb')
  return (
    <group position={[0.3,10, -0.2]}  scale={0.26}>
      <group   rotation={[Math.PI / 2.3 , 0, 0]} scale={[0.03, 0.05, 0.03]}>
        <mesh geometry={nodes.Circle001.geometry} material={materials['Material.001']} position={[0, 0.56, 0]} rotation={[Math.PI / 2, 0, 0]} scale={[2.45, 1, 1]} />
        <mesh geometry={nodes.Cylinder002.geometry} material={materials['Material.002']} position={[0, -8.02, 0]} rotation={[Math.PI / 2, 0, 0]} scale={[6.94, 6.94, 1.3]} />
      </group>
      </group>
  )
}

useGLTF.preload('../models/React.glb')
