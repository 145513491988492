import mds from "../../src/assets/works/mds.jpg"
import ac from "../../src/assets/works/AndeanCeramicsJs.jpg"
import ac3 from "../../src/assets/works/AndeanCeramicsReact.jpg"



export const WorkItems=({servicios})=>{


  return (
    <>
          
    {/* {
      (servicios.portfolio === "WebDevelopment") &&
      <>
      
     <div className="workItem">
        <img src={servicios.img} className=" " alt={""} />
        <div className="work_detail">
          <p className="work_title">{servicios.nombre}</p>
          <p className="work_tipo">
          {servicios.descrip}
          </p>
        </div>
        </div>
      </>

    } */}



    {
      ((servicios.portfolio === "XrDevelopment")  || (servicios.portfolio === "GameDevelopment") || (servicios.portfolio === "WebDevelopment")) &&
      <>
       
       <div className="workItem">
        <iframe
          className="playerVimeo"
          src={servicios.url}
          // width="100%"
          // height="100%"
          
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          // title={servicios.descrip}
       
          
        >
   
        </iframe>
        <div className="work_detail2">
          <p className="work_title">{servicios.nombre}</p>
          <p className="work_tipo">
           {servicios.descrip}
          </p>
        </div>
      </div>

      
      </>       

        
    }
 
    

{/* 
      <div className="workItem">
        <img src={ac} className=" " alt={""} />
        <div className="work_detail">
          <p className="work_title">ANDEAN CERAMICS (E-Commerce)</p>
          <p className="work_tipo">
            Web Development: React JS - Sass - Bootstrap - Firebase - Stripe -
            WEBXR
          </p>
        </div>
      </div>

      <div className="workItem">
        <img src={ac3} className=" " alt={""} />
        <div className="work_detail">
          <p className="work_title">ANDEAN CERAMICS (E-Commerce)</p>
          <p className="work_tipo">
            Web Development: React JS - Sass - Bootstrap - Firebase - Stripe -
            WEBXR
          </p>
        </div>
      </div>

      <div className="workItem">
        <iframe
          className="playerVimeo"
          src="https://player.vimeo.com/video/793242918?h=e5ae4e3765&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          width="100%"
          height="300px"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          title="Warship demo.mp4"
        >
          {" "}
        </iframe>
        <div className="work_detail">
          <p className="work_title">ANDEAN CERAMICS (E-Commerce)</p>
          <p className="work_tipo">
            Web Development: React JS - Sass - Bootstrap - Firebase - Stripe -
            WEBXR
          </p>
        </div>
      </div>

      <div className="workItem">
        <iframe
          className="playerVimeo"
          src="https://player.vimeo.com/video/793243324?h=ffc1ee152f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          width="100%"
          height="300px"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          title="Demo GameShooter"
        ></iframe>
        <div className="work_detail">
          <p className="work_title">ANDEAN CERAMICS (E-Commerce)</p>
          <p className="work_tipo">
            Web Development: React JS - Sass - Bootstrap - Firebase - Stripe -
            WEBXR
          </p>
        </div>
      </div> */}
    </>
  );
}

