/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import * as THREE from "three"
import React, { useEffect, useRef, useState } from 'react'
import { useGLTF, PerspectiveCamera, useAnimations } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

const color = new THREE.Color()

export function Model({ fovCam, scroll, ...props }) {
  const group = useRef()
  // const { nodes, materials, animations } = useGLTF('../models/Model.glb')
  const { nodes, materials, animations } = useGLTF('../models/ModelV2.glb')
  const { actions } = useAnimations(animations, group)

  const [hovered, set] = useState()
  const extras = { receiveShadow: true, castShadow: true, "material-envMapIntensity": 0.2 }
  useEffect(() => void (actions["CameraAction.005"].play().paused = true), [])
 
  useEffect(() => {
    if (hovered) group.current.getObjectByName(hovered).material.color.set("white")
    document.body.style.cursor = hovered ? "pointer" : "auto"
  }, [hovered])

  useFrame((state) => {
    actions["CameraAction.005"].time = THREE.MathUtils.lerp(actions["CameraAction.005"].time, actions["CameraAction.005"].getClip().duration * scroll.current, 0.05)

    group.current.children[0].children.forEach((child, index) => {
      // child.material.color.lerp(color.set(hovered === child.name ? "tomato" : "#202020").convertSRGBToLinear(), hovered ? 0.1 : 0.05)
      

      const et = state.clock.elapsedTime
      child.position.y = Math.sin((et + index * 2000) / 2) * 1
      child.rotation.x = Math.sin((et + index * 2000) / 3) / 10
      child.rotation.y = Math.cos((et + index * 2000) / 2) / 10
      child.rotation.z = Math.sin((et + index * 2000) / 3) / 10
    })
  })

  return (
    <group ref={group} {...props} dispose={null}>
 
        <group  onPointerOver={(e) => (e.stopPropagation(), set(e.object.name))}
        onPointerOut={(e) => (e.stopPropagation(), set(null))}
        position={[0.06, 4.04, 1.35]}
        scale={[0.25, 0.25, 0.25]}>

          {/* <mesh name="Headphones" geometry={nodes.Headphones.geometry} material={materials.M_Headphone} {...extras}  />
         */}
         
  
         <group name="Oculus"  position={[8.25,-20, 27.64]} scale={0.0035}  >
            <mesh name="OculustMesh" geometry={nodes.OculustMesh.geometry}  position={[0,-2180, 0]}  material={materials.Power_Botton__0}  rotation={[Math.PI / 3.5, 2.5, 3.8]}{...extras}   />
            <mesh name="OculustMesh_1" geometry={nodes.OculustMesh_1.geometry} material={materials.OnOff} position={[0,-2180, 0]}   rotation={[Math.PI / 3.5, 2.5, 3.8]}{...extras}  />
            <mesh name="OculustMesh_2" geometry={nodes.OculustMesh_2.geometry} material={materials.Negro}position={[0,-2180, 0]}   rotation={[Math.PI / 3.5, 2.5, 3.8]}{...extras} />
            <mesh name="OculustMesh_3" geometry={nodes.OculustMesh_3.geometry} material={materials.Lentes} position={[0,-2180, 0]}   rotation={[Math.PI / 3.5, 2.5, 3.8]}{...extras}  />
            <mesh name="OculustMesh_4" geometry={nodes.OculustMesh_4.geometry} material={materials.Carcaza} position={[0,-2180, 0]}    rotation={[Math.PI / 3.5, 2.5, 3.8]}{...extras} />
          </group>
          
         <group name="Heart" position={[33.34, -2.78, 10.89]} rotation={[0, 0.76, 0]} scale={8.99}>
          
           <mesh name="Base2_lambert3_0001" geometry={nodes.Base2_lambert3_0001.geometry} material={materials['lambert3.001']} {...extras}/>
           <mesh name="Base2_lambert3_0001_1" geometry={nodes.Base2_lambert3_0001_1.geometry} material={materials['lambert2.001']} {...extras}/>
            <group name="skeletalHeart" position={[-0.28, -0.24, -0.01]} rotation={[0, 1, -0.39]} scale={[0.1, 0.09, 0.08]}>
              <primitive object={nodes.Root4} />
              <skinnedMesh name="heart2" geometry={nodes.heart2.geometry} material={materials['Material_0.001']} skeleton={nodes.heart2.skeleton} />
            </group>
            <spotLight position={[0, -0.5, 0.0]} args={["#ffffff", 180, 3, -Math.PI /1, 1.9] } />
          </group>

          <group   >
          <mesh name="Game" geometry={nodes.Game.geometry} material={materials['Material.001']} position={[40.73, 8, -18.33]} rotation={[Math.PI / 2.3, -0.2, -0.5]} scale={4.0}{...extras} />
          </group>
         <group name="Web" position={[30.33, 0.0, -27.37]}  scale={4.0}>
            <mesh name="Cube332_Cube001" geometry={nodes.Cube332_Cube001.geometry} material={materials['08.Website_Icon']}   position={[-2,6, 0]}  rotation={[Math.PI / 1.5, 6.5, -2.5]} {...extras}/>
            <mesh name="Cube332_Cube001_1" geometry={nodes.Cube332_Cube001_1.geometry} material={materials['02.Website_Icon']} position={[-2,6, 0]}  rotation={[Math.PI / 1.5, 6.5, -2.5]} {...extras}/>
            <mesh name="Cube332_Cube001_2" geometry={nodes.Cube332_Cube001_2.geometry} material={materials['11.Keyword_Icon']}  position={[-2,6, 0]}  rotation={[Math.PI / 1.5, 6.5, -2.5]} {...extras}/>
            <mesh name="Cube332_Cube001_3" geometry={nodes.Cube332_Cube001_3.geometry} material={materials['15.Website_Icon']}  position={[-2,6, 0]}  rotation={[Math.PI / 1.5, 6.5, -2.5]}{...extras}/>
            <mesh name="Cube332_Cube001_4" geometry={nodes.Cube332_Cube001_4.geometry} material={materials['11.Website_Icon']}  position={[-2,6, 0]}  rotation={[Math.PI / 1.5, 6.5, -2.5]}{...extras}/>
            <mesh name="Cube332_Cube001_5" geometry={nodes.Cube332_Cube001_5.geometry} material={materials['12.Website_Icon']}  position={[-2,6, 0]}  rotation={[Math.PI / 1.5, 6.5, -2.5]}{...extras}/>
            <mesh name="Cube332_Cube001_6" geometry={nodes.Cube332_Cube001_6.geometry} material={materials['03.Website_Icon']} position={[-2,6, 0]}  rotation={[Math.PI / 1.5, 6.5, -2.5]}{...extras}/>
            <mesh name="Cube332_Cube001_7" geometry={nodes.Cube332_Cube001_7.geometry} material={materials['01.Website_Icon']} position={[-2,6, 0]}   rotation={[Math.PI / 1.5, 6.5, -2.5]} {...extras}/>
            <mesh name="Cube332_Cube001_8" geometry={nodes.Cube332_Cube001_8.geometry} material={materials['04.Keyword_Icon.002']}  position={[-2,6, 0]} rotation={[Math.PI / 1.5, 6.5, -2.5]} {...extras}/>
            <mesh name="Cube332_Cube001_9" geometry={nodes.Cube332_Cube001_9.geometry} material={materials['12.Keyword_Icon']}  position={[-2,6, 0]}  rotation={[Math.PI / 1.5, 6.5, -2.5]}{...extras} />
            <mesh name="Cube332_Cube001_10" geometry={nodes.Cube332_Cube001_10.geometry} material={materials['13.Keyword_Icon']}  position={[-2,6, 0]}  rotation={[Math.PI / 1.5, 6.5, -2.5]} {...extras}/>
            <mesh name="Cube332_Cube001_11" geometry={nodes.Cube332_Cube001_11.geometry} material={materials['02.Keyword_Icon.001']}  position={[-2,6, 0]}  rotation={[Math.PI / 1.5, 6.5, -2.5]}{...extras}/>
            <mesh name="Cube332_Cube001_12" geometry={nodes.Cube332_Cube001_12.geometry} material={materials['07.Keyword_Icon.001']}  position={[-2,6, 0]}  rotation={[Math.PI / 1.5, 6.5, -2.5]}{...extras}/>
            <mesh name="Cube332_Cube001_13" geometry={nodes.Cube332_Cube001_13.geometry} material={materials['06.Website_Icon']}  position={[-2,6, 0]}  rotation={[Math.PI / 1.5, 6.5, -2.5]} {...extras}/>
            <mesh name="Cube332_Cube001_14" geometry={nodes.Cube332_Cube001_14.geometry} material={materials['07.Website_Icon']} position={[-2,6, 0]}  rotation={[Math.PI / 1.5, 6.5, -2.5]} {...extras}/>
            <mesh name="Cube332_Cube001_15" geometry={nodes.Cube332_Cube001_15.geometry} material={materials['05.Website_Icon']}  position={[-2,6, 0]}  rotation={[Math.PI / 1.5, 6.5, -2.5]}{...extras}/>
          </group>

          <group  position={[0.67,0, -49.58]} scale={14.98}>
          <mesh name="Cart" geometry={nodes.Cart.geometry} material={materials.Cart}  position={[0,1.3, 0]}rotation={[1.5, 0.00, 0]} {...extras} />
          <mesh name="Cart" geometry={nodes.Cart.geometry} material={materials.Cart}  position={[0,1.3, 0]}rotation={[1.5, 0.00, 0]} {...extras} />
          </group>

          {/* <mesh name="Notebook" geometry={nodes.Notebook.geometry} material={materials.M_Notebook} />
          <mesh name="Zeppelin" geometry={nodes.Zeppelin.geometry} material={materials.M_Zeppelin} />
           */}
  
          {/* <mesh name="Cube004" geometry={nodes.Cube004.geometry} material={materials.orange} position={[-0.25, -20.73, 30.89]} rotation={[2.11, -0.79, 0]} scale={3.99} {...extras} />
          <mesh name="Cube004_1" geometry={nodes.Cube004_1.geometry} material={materials.white} position={[-0.25, -20.73, 30.89]} rotation={[2.11, -0.79, 0]} scale={3.99} {...extras} />
       
          <mesh name="Notebook" geometry={nodes.Notebook.geometry} material={materials.M_Notebook} {...extras}  />
          <mesh name="Rocket003" geometry={nodes.Rocket003.geometry} material={materials.M_Rocket} {...extras}  />
          <mesh name="Roundcube001" geometry={nodes.Roundcube001.geometry} material={materials.M_Roundcube} {...extras}  />
          <mesh name="Table" geometry={nodes.Table.geometry} material={materials.M_Table}{...extras}  />
          <mesh name="Zeppelin" geometry={nodes.Zeppelin.geometry} material={materials.M_Zeppelin} {...extras} /> */}
        </group>
        
        <group name="Camera"  position={[-1.78, 2.04, 23.58]}  rotation={[1.62, 0.01, 0.11]}>
        <PerspectiveCamera makeDefault far={100} near={0.1} fov={fovCam} rotation={[-Math.PI / 2, 0, 0]}>
          <directionalLight
            // castShadow
            position={[10, 20, 15]}
            shadow-camera-right={8}
            shadow-camera-top={8}
            shadow-camera-left={-8}
            shadow-camera-bottom={-8}
            shadow-mapSize-width={1024}
            shadow-mapSize-height={1024}
            intensity={2}
            shadow-bias={-0.0001}
          />
        </PerspectiveCamera>
        </group>
  
    </group>
  )
}

useGLTF.preload('../models/Model.glb')
export default Model;