/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Css3() {
  const { nodes, materials } = useGLTF('../models/css3.glb')
  return (
    <group  dispose={null} scale={0.3} position={[-1.5,9,0.5]}>
      
      <group rotation={[279.35, -0.5,-0.02]}>
        <mesh geometry={nodes.Cube002.geometry} material={materials['orange.001']} />
        <mesh geometry={nodes.Cube002_1.geometry} material={materials['Material.003']} />
      </group>
    </group>
  )
}

useGLTF.preload('../models/css3.glb')
