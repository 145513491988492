/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 UnityParent.glb
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
export function Unity() {
  const { nodes, materials } = useGLTF('../models/Unity.glb')
  const {viewport} = useThree();
  return (
    
    <group position={[2.2,5,-1.8]} scale={0.22} rotation={[-Math.PI/-1.1, -2.5, 3]}>
    <group rotation={[-Math.PI, 0, 0]} scale={0.03}>
      <mesh geometry={nodes.Line007.geometry} material={materials['Material.001']} position={[0.01, -40.3, 7.87]} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes.Line008.geometry} material={materials['Material.002']} position={[-33.21, 17.82, 7.87]} rotation={[-Math.PI / 2, -1.05, Math.PI]} />
      <mesh geometry={nodes.Line009.geometry} material={materials['Material.003']} position={[33.25, 17.82, 7.87]} rotation={[Math.PI / 2, -1.05, Math.PI]} scale={-1} />
    </group>
    <mesh geometry={nodes.Cylinder.geometry} material={materials['Material.013']} position={[0, 0, -0.25]} rotation={[Math.PI / 2, 0, 0]} scale={[2.8, -0.26, 2.8]} />
 
  </group>
  )
}

useGLTF.preload('../models/Unity.glb')
