
import { Center, Text3D } from '@react-three/drei'
import { useThree,useFrame } from '@react-three/fiber'
import * as THREE from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { useLayoutEffect, useMemo, useRef } from 'react';



export const  TextFor3D_Desc=({ view,children, vAlign = 'center', hAlign = 'center', size = 2, color = 'yellow', ...props})=>{
  const {viewport} = useThree();

  const config = useMemo(
    () => ({ size: 25, height: 1, curveSegments: 32, bevelEnabled: true, bevelThickness: 0.2, bevelSize:0.2, bevelOffset: 0.1, bevelSegments: 8 }),
    []
  )
  const mesh = useRef()
  useLayoutEffect(() => {
    // const size = new THREE.Vector3()
    // mesh.current.geometry.computeBoundingBox()
    // mesh.current.geometry.boundingBox.getSize(size)
    // mesh.current.position.x = hAlign === 'center' ? -size.x / 2 : hAlign === 'right' ? 0 : -size.x
    // mesh.current.position.y = vAlign === 'center' ? -size.y / 2 : vAlign === 'top' ? 0 : -size.y
  }, [children])


// const fontLoader = new FontLoader();

// fontLoader.load(
//   '../models/gentilis_bold.typeface.json',
//   (droidFont) => {
//       // console.log(droidFont)
//       const textGeometry = new TextGeometry('HOLAAAA', {
//         size: 4,
//         height: 7,
//         font: droidFont,
//         curveSegments: 10,
//         bevelEnabled: false,
//         bevelOffset: 0,
//         bevelSegments: 1,
//         bevelSize: 0.3,
//         bevelThickness: 1,
//         color: "red"
        
//       });
//       const textMaterial = new THREE.MeshPhongMaterial();
//       const textMesh = new THREE.Mesh(textGeometry, textMaterial);
//       textMesh.position.x = 1;
//       textMesh.position.y = 2;
//       textMesh.position.z = 2;
//     })

  
  return (
    <>

        {/* <group {...props}  scale={[0.003 * size, 0.004 * size, size*0.003]}> */}
        <group {...props}   scale={(viewport.width/view)}>
      <Text3D ref={mesh} font={'../models/Romande ADF No2 Std_Italic.json'} {...config}>
        {children}
        {/* <meshNormalMaterial /> */}
        <meshBasicMaterial color = 'white'/>
        {/* <meshPhysicalMaterial color = 'red'/> */}
      {/* <meshPhongMaterial /> */}
      
    
      </Text3D>
      
      </group>
 
     
    </>
  )
 
}
