/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/



import { RenderTexture } from '@react-three/drei'
import React, { useRef } from 'react'


export default function Cylinder(props) {


  return (
    <mesh   rotation={[Math.PI /1, -5, 3.12]} position={[-3.5 ,0.2,-2]}  >
       <cylinderGeometry attach="geometry" args={[0.35, 0.35, 0.9,18]} />
     
    {/* <meshStandardMaterial metalness={1} roughness={4} /> */}
    <meshStandardMaterial  >
    <RenderTexture attach="map" anisotropy={16} >
        {/* <PerspectiveCamera makeDefault manual aspect={1 / 1} position={[-1.2, -0.1, 5]} /> */}
          <color attach="background" args={['Red']}  />   
          {/* <Text ref={textRef} fontSize={1} color="White">
            Hello!
          </Text> */}
        </RenderTexture>
      </meshStandardMaterial >

  </mesh>



  )
}


