import { Canvas } from '@react-three/fiber';
import React,{ Suspense, useRef,useEffect  } from 'react';

import { PerspectiveCamera } from 'three';

import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { TextFor3D } from './components/TextFor3D';
import { Model } from './components/Model';


import { MiNavbar } from './components/MiNavbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import { Stars, } from "@react-three/drei";
import { Three } from './components/Three';
import { Expertise } from './components/Expertise';
// import { Portfolio } from './components/Portfolio';
import { WorkItems } from './components/WorkItems';
import PacmanLoader from "react-spinners/PacmanLoader";
import Resume from './components/Resume';




// const Three = React.lazy(() => import('./components/Three'))
// const Expertise = React.lazy(() => import('./components/Expertise'))
const Portfolio = React.lazy(() => import('./components/Portfolio'))

function App() {



  return (
     <BrowserRouter>
 
      <Routes>

     
      {/* <Route  path='/' element={<Three/> }/> */}
      <Route path='/' element={  <Suspense fallback={<Loading />}><Three /> </Suspense>}/>
      {/* <Route path='/' element={  <Suspense fallback={<h1>loading</h1>}><Three /> </Suspense>}/> */}
      <Route path='/expertise' element={  <Suspense fallback={<Loading />}><Expertise/></Suspense>}/>
      {/* <Route path='/expertise' element={  <Expertise/>}/> */}
      <Route path='/portfolio' element={ <Suspense fallback={<Loading />}> <Portfolio/></Suspense>}/>

      <Route path='/portfolio/:tipoPortfolio' element={<Portfolio/>}/>
      <Route path='/resume' element={<Resume/>}/>
        {/* <div className="canvas_container2">
          <Canvas>
            <ambientLight intensity={1} />

            <Model scroll={scroll} />
            <Environment preset="city" />
          </Canvas>
          <Overlay ref={overlay} caption={caption} scroll={scroll} />
        </div>
       */}
        {/* <Canvas
     onCreated={(state) => state.events.connect(overlay.current)}
     raycaster={{ computeOffsets: ({ clientX, clientY }) => ({ offsetX: clientX, offsetY: clientY }) }}
    shadows>
        <ambientLight intensity={1} />
        <Suspense fallback={null}>
        
        <Model scroll={scroll} />
        <Environment preset="city" />
        </Suspense>
      </Canvas> */}
        {/* <Overlay ref={overlay} caption={caption} scroll={scroll} /> */}

      </Routes>
      
      <MiNavbar className2="navBar_content"/>
      </BrowserRouter>
   
 
  );
}
export default App;


function Loading() {
  
  return  (
    <div className="Loading" >
    <PacmanLoader
    color={"#ecdede"}
    loading={true}
    size={30}
    aria-label="Loading Spinner"
    data-testid="loader"
     />
        
    </div>
  );
}

