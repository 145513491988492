/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 Wordpress.glb
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Wordpress() {
  const { nodes, materials } = useGLTF('../models/Wordpress.glb')
  return (

  
    <group position={[0.2,11,0.5]} rotation={[Math.PI / 2.5, 0, 0]} scale={0.007}>
      <mesh geometry={nodes.Base.geometry} material={materials['Material.001']} position={[0, -6.58, 0]} rotation={[Math.PI / 2, 0, 0]} scale={[1.1 , 1.1 , 2]} />
      <mesh geometry={nodes.Line001.geometry} material={materials['Material.002']} position={[0.86, 10, -11.92]} rotation={[Math.PI / 2, 0, 0]} />
    </group>

   
  )
}

useGLTF.preload('../models/Wordpress.glb')
