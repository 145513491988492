import { Environment } from "@react-three/drei";
import Overlay from "../components/Overlay"
import { Model } from '../components/Model';
import { Canvas } from '@react-three/fiber';
import  React,{ Suspense, useRef } from "react";
import { MiNavbar } from "./MiNavbar";
import PacmanLoader from "react-spinners/PacmanLoader";
import MediaQuery from 'react-responsive'

export const Expertise=()=>{

  const scroll = useRef(0)

  const overlay = useRef()
  const caption = useRef()

  // const Model = React.lazy(() => import('../components/Model'))
  return (
    <>
        {/* <div className="canvas_container2"> */}
          
         <Canvas shadows 
    //  onCreated={(state) => state.events.connect(overlay.current)}
    //  raycaster={{ computeOffsets: ({ clientX, clientY }) => ({ offsetX: clientX, offsetY: clientY }) }}
     >
        <color attach="background" args={["#202020"]} />
          <fog attach="fog" args={["#202020", 15, 25]} />

        <ambientLight intensity={1} />
        {/* <Suspense fallback={<Loading />}> */}
        <MediaQuery maxWidth={720} >

        {(matches) =>
          matches
            ?  <Model scroll={scroll} fovCam={50} />
          :  <Model scroll={scroll}  fovCam={28} />
        }
          </MediaQuery>
        
          {/* </Suspense> */}
        {/* <Environment preset="city" /> */}
    
      </Canvas>
     
      
      <Overlay ref={overlay} caption={caption} scroll={scroll} />
     
        {/* </div> */}
       
  
    
    </>
  );
}

function Loading() {
  return  (
    <div className="Loading" >
    <PacmanLoader
    color={"#ecdede"}
    loading={true}
    size={30}
    aria-label="Loading Spinner"
    data-testid="loader"

     />
    </div>
  );
}

export default Expertise;


