/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { RenderTexture } from '@react-three/drei'

import React, { useRef } from 'react'


export default function Sphere(props) {

  return (
    <mesh  position={[-0.,0,0]}  >
       
     
    <sphereGeometry args={[0.3, 64, 64]} />
    {/* <meshStandardMaterial metalness={1} roughness={4} /> */}
    <meshStandardMaterial  >
    <RenderTexture attach="map" anisotropy={16} >
        {/* <PerspectiveCamera makeDefault manual aspect={1 / 1} position={[1, -0.1, 5]} /> */}
          <color attach="background" args={['Orange']}  />   
          {/* <Text ref={textRef} fontSize={1} color="White">
            Hello!
          </Text> */}
        </RenderTexture>
      </meshStandardMaterial >

  </mesh>



  )
}


