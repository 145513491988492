
import { Canvas ,extend,useFrame} from '@react-three/fiber';
import { Suspense, useRef } from 'react';

import { Environment,Stars, OrbitControls, PerspectiveCamera,Effects } from "@react-three/drei";

import { Physics, RigidBody } from '@react-three/rapier';
import { TextFor3D } from '../components/TextFor3D';
import Html5 from "../components/Html5";
import { Css3 } from '../components/Css3';
import { Js } from '../components/Js';
import { Floor } from '../components/Floor';
import { Floor_Luces } from '../components/Floor_Luces';
import { MiNavbar } from './MiNavbar';
import { Unity } from './Unity';

import { Unreal } from './Unreal';
import { ReactModel } from './ReactModel';
import { Wordpress } from './Wordpress';

import Cube from './Cube';
import Sphere from './Sphere';
import Cylinder from './Cilynder';


import { WaterPass, GlitchPass } from 'three-stdlib'
import { TextFor3D_Desc } from './TextFor3D_Desc';

import { useEffect, useState } from 'react'
import PacmanLoader from "react-spinners/PacmanLoader";
import MediaQuery from 'react-responsive'
import { useThree } from '@react-three/fiber'
extend({ WaterPass, GlitchPass })

export const Three=()=>{


  // const [loading, setLoading] =useState([true])

//   const obtenerComponente=()=>{
//     return new Promise((resolve, reject)=>{
//         setTimeout(()=>{
         
//           resolve(Suspense)

    
//           reject(err =>console.error(err))
//         },3000);
//     })
    
// }


// useEffect(()=>{


   
//   const funcionAsincrona =async()=>{
//     try {

      
//       const miComponente = await obtenerComponente();
 
    
//     } catch (error) {
//         console.log("Hubo error",error);
//     }

  
//    finally{
//       console.log(' coompleted transaccion nuevo')
//       setLoading(false)
    
//       // console.log("dentro finnaly"+loading);

//   }
  
//   }
//   funcionAsincrona();

// },[])


  return (
    <>
    
          {/* {
      loading ?
       (
        <div className="Loading" >
        <Loading load = {loading} ></Loading>
        </div>
     )

     :
     ( */}

     
      <div className="canvas_container" >
      {/* <Suspense fallback={<Loading />}> */}
        {/* <Canvas shadows dpr={[1, 2]}> */}
        <Canvas shadows>
   
          <color attach="background" args={["#202020"]} />
             
          <fog attach="fog" args={["#202020", 5, 20]} />
          <OrbitControls autoRotate enablePan={false} enableZoom={false}   minPolarAngle={Math.PI / 2.4}
         maxPolarAngle={Math.PI / 4.5} autoRotateSpeed={0.2} />
              
          <ambientLight intensity={0.01} />
          {/* <PerspectiveCamera makeDefault position={[0, 8, 0 ]}   /> */}
             <MediaQuery maxWidth={720} >

            {(matches) =>
              matches
                ?  <PerspectiveCamera makeDefault position={[0, 9, 0]}  fov={70}  />
              :  <PerspectiveCamera makeDefault position={[0, 8, 0 ]}   />
            }
            </MediaQuery>
        
           
       

            <Physics colliders={false}>
              <RigidBody gravityScale={0.2} colliders="hull">
             
                <Html5 />
        
              </RigidBody>

              <RigidBody gravityScale={0.1} colliders="hull">
                <Css3 />
              </RigidBody>

              <RigidBody gravityScale={0.1} colliders="hull">
                <Js />
              </RigidBody>

              <RigidBody gravityScale={0.1} colliders="trimesh">
                <Unity />
              </RigidBody>

              <RigidBody gravityScale={0.3}  colliders="trimesh">
                <Unreal />
              </RigidBody>

              <RigidBody gravityScale={0.2}   colliders="trimesh">
                <ReactModel />
              </RigidBody>

              <RigidBody gravityScale={0.2} colliders="trimesh">
          
                <Wordpress />
              </RigidBody>

              <RigidBody colliders="cuboid" density={10} gravityScale={3}>
                 <Cylinder/>
              </RigidBody>

              <RigidBody  gravityScale={3} density={20} colliders="ball">
              <Sphere/>
              </RigidBody>

              <RigidBody colliders="cuboid"  gravityScale={3} density={10}>
              <Cube/>
              </RigidBody>

              <RigidBody colliders="cuboid"  gravityScale={0}>
                <Floor />
              </RigidBody>
            </Physics>
           
            <MediaQuery maxWidth={720} >
           
            {(matches) =>
              matches
                ?<TextFor3D  hAlign="right" position={[-2.4 , 3.2, 0]} view={250} children="Martin Collahua"/> 
               :<TextFor3D  hAlign="right" position={[-4.4, 1.8, 0]} view={650} children="Martin Collahua"/>
             }
            </MediaQuery>
           
            <MediaQuery maxWidth={720} >
            
            {(matches) =>
              matches
                ? <TextFor3D_Desc hAlign="right" position={[-2.4, 2.9, 0]} view={880} children="Systems Engineer, XR Developer & Front End"/>
              : <TextFor3D_Desc hAlign="right" position={[-4.4, 1.5, 0]} view={2280} children="Systems Engineer, XR Developer & Front End"/>
            }
            </MediaQuery>

           
          <Floor_Luces />
          {/* <Starts/> */}
            <Stars
            radius={100}
            depth={50}
            count={5000}
            factor={5}
            saturation={0}
            fade
            
          />
      
        </Canvas>
       
        {/* </Suspense> */}
{/*        
        <MiNavbar className2="navBar_content"/> */}

    
      </div>
          {/* )
    }   
   */}
    </>
  );
}

function Loading() {
  return  (
    <div className="Loading" >
    <PacmanLoader
    color={"#ecdede"}
    loading={true}
    size={30}
    aria-label="Loading Spinner"
    data-testid="loader"

     />
    </div>
  );
}

 export default Three; //Para el React.Lazy 