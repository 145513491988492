/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 Unreal.glb
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Unreal() {
  const { nodes, materials } = useGLTF('../models/Unreal.glb')
  return (

      <group position={[-2.8 ,6,-1.5]}  rotation={[Math.PI / 2.8, -0.8, 0]} scale={0.008}>
        <mesh geometry={nodes.Cylinder003.geometry} material={materials['Material.001']} rotation={[Math.PI / 2, 0, 0]} scale={[6.94, 6.94, 3]} />
        <mesh geometry={nodes.Letra.geometry} material={materials['Material.003']} position={[2.26, 8, 1.48]} rotation={[Math.PI / 2, 0, 0]} />
        <mesh geometry={nodes.Line010.geometry} material={materials['Material.004']} position={[0.98, 15, -13.54]} rotation={[Math.PI / 2, 0, 0]} scale={[1.14, 1.14, 1]} />
      </group>
    
  )
}

useGLTF.preload('../models/Unreal.glb')
