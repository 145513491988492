/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/


import React, { useRef } from 'react'

import { useFrame } from '@react-three/fiber'
import { RenderTexture,  PerspectiveCamera, Text,Effects } from '@react-three/drei'


export default function Cube(props) {

  const textRef = useRef()
  useFrame((state) => (textRef.current.position.x = Math.sin(state.clock.elapsedTime) * 1))
  return (
    <group>
    <mesh scale={[2, 1, 2]} position={[2.5,0,-2]}  rotation={[Math.PI /1, 4, 3.12]} >
   
      <boxGeometry />
      <meshStandardMaterial  >
        <RenderTexture attach="map" anisotropy={16} >
        <PerspectiveCamera makeDefault manual aspect={1 / 1} position={[0, -0.1, 5]} />
          <color attach="background" args={['Green']}  />   
          <Text ref={textRef} fontSize={1.3} color="White">
            Welcome!
          </Text>
        </RenderTexture>
      </meshStandardMaterial >
    </mesh>
  

    <mesh  scale={[0.4, 0.8, 0.4]} position={[2.5,0.7,-2]}  rotation={[Math.PI /1, 4, 3.12]}   >
    <boxGeometry />
     <meshStandardMaterial   metalness={88.2} roughness={0.1} >
        <RenderTexture attach="map" anisotropy={16}>
          <color attach="background" args={['Black']} />
          
        </RenderTexture>
     </meshStandardMaterial>  
    </mesh>


<mesh scale={[0.9, 0.8, 0.5]} position={[-1.5,-0.1,-0.8]}  rotation={[Math.PI /-4000, -0.2, 0]}>
    <boxGeometry />
    <meshStandardMaterial  >
    <RenderTexture attach="map" anisotropy={16} >
        {/* <PerspectiveCamera makeDefault manual aspect={1 / 1} position={[0, -0.1, 5]} /> */}
          <color attach="background" args={['Blue']}  />   
          {/* <Text ref={textRef} fontSize={2} color="White">
            Hello!
          </Text> */}
        </RenderTexture>
      </meshStandardMaterial>  
    </mesh>
    
    
</group>

    
  )
}


