import { usePlane } from '@react-three/cannon'
import { MovingSpot } from './MovingSpot';
import {  EnvironmentMap, useDepthBuffer } from '@react-three/drei'


export const Floor_Luces=()=>{
  const depthBuffer = useDepthBuffer({ frames: 1 })
    return(
        <>
      <MovingSpot depthBuffer={depthBuffer} color="white" position={[4, 2.8, 3]} />
      <MovingSpot depthBuffer={depthBuffer} color="White" position={[-4,2.8, 3]} />

       {/* <MovingSpot depthBuffer={depthBuffer} color="white" position={[3.5, 0.1, 4]} />
      <MovingSpot depthBuffer={depthBuffer} color="White" position={[-3.5,-0.1, 4]} /> */}
      {/* <MovingSpot depthBuffer={depthBuffer} color="White" position={[0,3.1, 2]} /> */}
      {/* <MovingSpot depthBuffer={depthBuffer} color="White" position={[-3.5,-0.1, -4]} />
      <MovingSpot depthBuffer={depthBuffer} color="White" position={[3.5,-0.1, -4]} /> */}
      {/* <MovingSpot depthBuffer={depthBuffer} color="#0c8cbf" position={[1, 3.5, 0]} /> */}
    <mesh receiveShadow  rotation={[-(Math.PI / 2), 0, 0]} position={[0, -0.41, 0]}>
      {/* <planeGeometry args={[70, 70]} />
      <meshStandardMaterial color="black" /> */}
          
           {/* <EnvironmentMap></EnvironmentMap> */}
           <planeGeometry args={[5,5]} />
        <meshPhongMaterial />

    </mesh>
    </>
    );
}