import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {NavLink, Link} from 'react-router-dom';

import { Suspense } from 'react';

export const  MiNavbar=({className2})=>{
  console.log({className2})
    return (
      <header className={className2}>
  

        <Nav  className="justify-content-center " >
     
            <Nav.Link  className='navBar_font' as={Link} to="/">Home</Nav.Link>
      
            <Nav.Link  className='navBar_font' as={Link} to="/expertise">Expertise</Nav.Link>
            <Nav.Link  className='navBar_font' as={Link} to="/portfolio">Portfolio</Nav.Link>
            <Nav.Link  className='navBar_font'

             href="https://drive.google.com/file/d/11POwaTHNn4-nbXOSBED2ru06CBgDqNKu/view?usp=sharing" target="_blank">Resume
            
            </Nav.Link>
        </Nav>

        
      </header>
    );
}
