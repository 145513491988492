/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Js() {
  const { nodes, materials } = useGLTF('../models/js.glb')
  return (
    <group  dispose={null} scale={0.4} position={[-1.2,14,0.7]}>
      <group rotation={[45.3, 0, 0]} >
        <mesh geometry={nodes.Text_1.geometry} material={materials['Material.001']} />
        <mesh geometry={nodes.Text_2.geometry} material={materials['Material.002']} />
      </group>
    </group>
  )
}

useGLTF.preload('../models/js.glb')
