


export const Resume=()=>{

 

  return (
    <>
      {/* <iframe  src="https://drive.google.com/drive/folders/1UnkFMIa6D9z_60Z8XjdYFVRHu-M7DtBQ">
       
      </iframe>
     */}
    
    </>
  );
}

export default Resume;