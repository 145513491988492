/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/


import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { Vector3 } from 'three'
import { useThree,useFrame } from '@react-three/fiber'

export default function Html5(props) {

  const { nodes, materials } = useGLTF('../models/html5.glb')
  

  return (
    <>
 
      <group dispose={null} scale={0.3} position={[-1.5,7,0]} >
      <group rotation={[279.38, -1.0,0]}>
    
        <mesh geometry={nodes.Cube004.geometry} material={materials.orange} />
        <mesh geometry={nodes.Cube004_1.geometry} material={materials.white} />
      </group>
 
    </group>
    </>
  )
}

useGLTF.preload('../models/html5.glb')
