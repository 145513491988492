/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/


import React, { useRef } from 'react'
import { SpotLight } from '@react-three/drei'
import { Vector3 } from 'three'
import { useThree,useFrame } from '@react-three/fiber'

export const  MovingSpot=({ vec = new Vector3(), ...props })=>{


  const light = useRef()
  const viewport = useThree((state) => state.viewport)
  useFrame((state) => {
    light.current.target.position.lerp(vec.set((state.mouse.x * viewport.width) / 2, (state.mouse.y * viewport.height) / 2,0), 0.1)
    light.current.target.updateMatrixWorld()
  })

  return <SpotLight castShadow ref={light} penumbra={1} distance={35} angle={.45} attenuation={5} anglePower={4} intensity={1.5} {...props} />
  
}
