import { usePlane } from '@react-three/cannon'
import { MovingSpot } from './MovingSpot';
import {  useDepthBuffer } from '@react-three/drei'


export const Floor=()=>{

    return(
        <>
    
    <mesh receiveShadow  rotation={[-(Math.PI / 2), 0, 0]} position={[0, -0.4, 0]}>
      {/* <planeGeometry args={[70, 70]} />
      <meshStandardMaterial color="black" /> */}
           <planeGeometry args={[9, 9]}  />
        <meshPhongMaterial color="red" />
    </mesh>
    <mesh scale={7} receiveShadow  rotation={[-Math.PI / 2., 0, 0.8]}   position={[0, -0.38, 0]}>
    <meshStandardMaterial color="white"  metalness={-30}/>
        <ringGeometry args={[0.9, 1, 4, 1]} />
        </mesh>


    </>
    );
}