import React, { forwardRef } from "react"


const Overlay = forwardRef(({ caption, scroll }, ref) => (

  <div
  ref={ref}
  
    onScroll={(e) => { 
      scroll.current = e.target.scrollTop / (e.target.scrollHeight - window.innerHeight)

      // caption.current.innerText = scroll.current.toFixed(2)
      //  caption.current.innerText = scroll.current
      //  { console.log("mioverlay es: "+ scroll.current.toFixed(2))}
      // console.log(state);
        if(scroll.current.toFixed(2)<0.2)
        {
          if(caption.current.innerHTML === ``)
          {
            caption.current.innerHTML = 
   
            `    <div className="arrow " >
            <span></span>
            <span></span>
            <span></span>
            </div>
      
             `
          }
      
       }
       else{
        caption.current.innerHTML =``;

      }
     
  
     
    // if(scroll.current.toFixed(2)<0.3){
     

    //   state =true;
    // }
    // else{
    //   state=false;
    // }

    // if(state)
    // {
    //   caption.current.innerHTML = 

    //   `<div className="arrow " >
    //   <span></span>
    //   <span></span>
    //   <span></span>
    //   </div>
    //    `
    // }
    // else{
    //       caption.current.innerHTML =``;
    // }
 
  }}
     
    
    
    class="scroll">
    <div style={{ height: "400vh" }}>
      <div class="dot">
        <h1>Virtual Reality</h1>
      <div className="descripOverlay"> Skilled in developing VR with Unity and Oculus Quest.</div>
      </div>
    </div>
    <div style={{ height: "200vh" }}>
      <div class="dot">
        <h1>Augemented Reality</h1>
        <div className="descripOverlay">  Passionate about AR and WebAR. Experienced in Vuforia and WebAr with ModelViewer from Google.</div> 
     </div> 
    </div>
    <div style={{ height: "200vh" }}>
      <div class="dot">
        <h1>Game Developer</h1>
       <div className="descripOverlay" > Developing gameplay and mechanics with Unity and Unreal Engine.</div>
      </div>
    </div>
    <div style={{ height: "200vh" }}>
      <div class="dot">
        <h1>Web Developer</h1>
        <div className="descripOverlay" >Experienced in web development with JavaScript, Wordpress, React Three Fiber, Html5, Css3, Bootstrap and Sass.</div>
      </div>
    </div>
    <div style={{ height: "200vh" }}>
      <div class="dot">
        <h1>Ecommerce</h1>
        <div className="descripOverlay" > Skilled in programming Ecommerce with React, including augmented reality.</div>
      </div>
    </div>
    {/* <div style={{ height: "200vh" }}>
      <div class="dot">
        <h1>laptop</h1>A laptop, laptop computer, or notebook computer is a small, portable personal computer (PC) with a screen and
        alphanumeric keyboard.
      </div>
    </div> */}
    {/* <div style={{ height: "200vh" }}>
      <div class="dot">
        <h1>zeppelin</h1>A Zeppelin is a type of rigid airship named after the German inventor Count Ferdinand von Zeppelin (German
        pronunciation: [ˈt͡sɛpəliːn]) who pioneered rigid airship development at the beginning of the 20th century.
      </div>
    </div> */}
    {/* </* <span class="caption" ref={caption}> */}
    {/* <span class="caption" ref={caption}> */}

     <div className="arrow"  ref={caption}>
      <span ></span>
      <span></span>
      <span></span>
      </div>
    {/* </span> */}
 
  </div>
  
))

export default Overlay
